<template>
  <!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
  <!--
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-50">
  <body class="h-full">
  ```
-->
  <div
    class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
    style="position: relative"
  >
    <img src="@/assets/southeast.jpg" alt="" class="bg-image h-full w-full" />
    <div class="sm:mx-auto sm:w-full sm:max-w-md z-10">
      <img class="mx-auto h-24 w-auto" :src="logo" alt="Workflow" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        智慧校园综合素质评价管理系统
      </h2>
      <p class="mt-2 text-center text-sm text-gray-200">
        欢迎登录学生综合素质评价系统
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md z-10">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="space-y-6">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              手机号/账号/学号
            </label>
            <div class="mt-1">
              <input
                id="username"
                name="username"
                type="text"
                autocomplete="用户名"
                required
                v-model="formData.username"
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              登录密码
            </label>
            <div class="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                v-model="formData.password"
                autocomplete="密码"
                required
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                class="
                  h-4
                  w-4
                  text-indigo-600
                  focus:ring-indigo-500
                  border-gray-300
                  rounded
                "
              />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                记住密码
              </label>
            </div>

            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                忘记密码？请联系管理员
              </a>
            </div>
          </div>

          <div>
            <button
              @click="handleSubmitForm"
              type="submit"
              class="
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
            >
              立即登录
            </button>
          </div>
        </div>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                {{ title }}
              </span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { title } from "@/settings.js";
export default {
  name: "login",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {},
  /**
   * 数据字段
   * @return {{checkbox: boolean, formData: {password: string, code: string, checked: boolean, username: string}}} [description]
   */
  data() {
    return {
      title,
      checkbox: false,
      logo:'',
      formData: {
        username: "",
        password: "",
        checked: false,
        code: "",
      },
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {},
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {
      this.getSystem()
  },
  
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    /**
     *  @actions:  获取系统配置文件
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/23 14:15
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    getSystem(){
      this.loading = true;
      this.$cloud.get('setting/get',{key:'logo'}).then((res)=>{
        this.loading = false;
        try {
          this.logo =JSON.parse(res).logo;
        } catch ( e ){
          this.logo = '';
        }
      }).catch(err=>{
        this.loading = false;
        this.$message.error(err);
      })
    },
    
    handleSubmitForm() {
      this.$cloud
        .login("login/index", this.formData)
        .then((res) => {
          if (res.type !== "student") {
            if (res.role === "admin") {
              this.$cloud.go("/pages/select/role");
            } else {
              this.$cloud.go("/pages/home/home");
            }
          } else {
            // 学生登录直接进入进行评分
            this.$cloud.go("/pages/select/score");
          }
        })
        .catch(err=>{
          this.$message.error(err)
        });
    },
  },
};
</script>
<style scoped>
.bg-image {
  width: 100%;
  filter: blur(12px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.bg-image::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
}
</style>
